import { EventBus } from "@/event-bus";
import {
  MetrcItem,
  MetrcItemCategory,
  MetrcItemModel,
  MetrcUnit
} from "@/interfaces/itemManager";
import { messagesService } from "@/services/messages.service";
import { metrcItemService } from "@/services/metrcItemManager.service";
import { PageNavAction } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import findKey from "lodash/findKey";
import forIn from "lodash/forIn";
import reduce from "lodash/reduce";
import { Validator } from "vee-validate";
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import LoadingWindowComponent from "../../loadingWindow/loadingWindow.component";
import Template from "./itemManagerForm.template.vue";

@Component({
  mixins: [Template]
})
export default class ItemManagerFormComponent extends Vue {
  @Inject("$validator") public $validator!: Validator;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public loading = false;
  public loadingStrain = false;
  public isEdit = false;
  public categoryList: MetrcItemCategory[] = [];
  public strainList = [];
  public defaultStrainList = [];
  public uomList: MetrcUnit[] = [];
  public selectedCategory: MetrcItemCategory | null = null;
  public disableBtns = false;
  public strainSearch: string | null = null;
  public debounceStrainSearch = debounce(
    async (search: string | null, context: ItemManagerFormComponent) => {
      context.loadingStrain = true;
      if (!search && context.defaultStrainList.length) {
        context.strainList = context.defaultStrainList;
        context.loadingStrain = false;
        return;
      }
      context.strainList = await metrcItemService.getStrains(
        search ? { name: search } : null
      );

      if (!search) {
        context.defaultStrainList = cloneDeep(context.strainList);
      }
      context.loadingStrain = false;
    },
    500
  );
  public model: MetrcItemModel = {
    item_category: null,
    name: null,
    unit_of_measure: null,
    strain: null,
    item_brand: null,
    administration_method: null,
    unit_cbd_percent: null,
    unit_cbd_content: null,
    unit_cbd_content_unit_of_measure: null,
    unit_cbd_content_dose: null,
    unit_cbd_content_dose_unit_of_measure: null,
    unit_thc_percent: null,
    unit_thc_content: null,
    unit_thc_content_unit_of_measure: null,
    unit_thc_content_dose: null,
    unit_thc_content_dose_unit_of_measure: null,
    unit_volume: null,
    unit_volume_unit_of_measure: null,
    unit_weight: null,
    unit_weight_unit_of_measure: null,
    serving_size: null,
    supply_duration_days: null,
    number_of_doses: null,
    ingredients: null,
    description: null
  };

  public async save() {
    if (await this.$validator.validateAll()) {
      this.disableBtns = true;
      let result;
      this.$modals
        .load(LoadingWindowComponent, {
          closable: false,
          size: "fit",
          positionY: "center",
          positionX: "center",
          backdrop: true
        })
        .then(
          _ => {
            messagesService.renderSuccessMessage(
              (this.isEdit && "metrc.item_update_ok") || "metrc.item_save_ok"
            );
            this.$router.back();
          },
          () => {
            // nothing to do on catch
          }
        );
      this.validateModel();
      const missingVal = this.missingInModel();
      if (missingVal) {
        this.disableBtns = false;
        this.updateLoadingWindow({
          errors: [
            {
              message: this.$t("metrc.not_supported_key", {
                field: missingVal
              }).toString()
            }
          ]
        });
        return;
      }
      if (this.isEdit) {
        result = await metrcItemService.updateItem(this.model);
      } else {
        result = await metrcItemService.createItem(this.model);
      }
      this.updateLoadingWindow(result);
    }
    this.disableBtns = false;
  }

  public get requiredFields(): { [key: string]: boolean } {
    return (
      (!!this.selectedCategory &&
        reduce(
          this.selectedCategory,
          (acc: { [key: string]: boolean }, value, key) => {
            if (value && key.includes("requires")) {
              acc[key] = !!value;
            }

            return acc;
          },
          {}
        )) ||
      {}
    );
  }

  public get volumeList() {
    return this.uomList.filter(unit => unit.quantity_type === "VolumeBased");
  }

  public get weightList() {
    return this.uomList.filter(unit => unit.quantity_type === "WeightBased");
  }

  public categoryChanged() {
    this.model.item_category =
      (this.selectedCategory && this.selectedCategory.name) || null;
    this.$validator.reset();
  }

  @Watch("strainSearch")
  public strainSearchChanged() {
    if (this.strainSearch === "" || this.strainSearch === undefined) {
      this.model.strain = null;
    }

    if (!this.model.strain || !this.strainList.length) {
      this.debounceStrainSearch(this.strainSearch, this);
    }
  }

  public async mounted() {
    this.setPageNav({
      title: this.$t("metrc.metrc_item_mgm").toString(),
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.save,
            vuetifyProps: () => ({
              disabled: this.loading || this.disableBtns,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            vuetifyProps: () => ({
              disabled: this.disableBtns,
              fab: true,
              small: true
            }),
            action: () => {
              this.$router.back();
            }
          }
        ]
      }
    });
    this.loading = true;
    if (this.$route.params.id) {
      await metrcItemService.findItem(this.$route.params.id).then(
        data => {
          this.model = data;
          this.isEdit = true;
        },
        () => this.$router.back
      );
    }
    this.fetchFormData();
  }

  protected async fetchFormData() {
    [this.categoryList, this.uomList] = await Promise.all([
      metrcItemService.getCategories({ per_page: 100 }),
      metrcItemService.getUOM()
    ]);
    if (this.isEdit) {
      this.selectedCategory =
        this.categoryList.find(c => c.name === this.model.item_category) ||
        null;
    }
    this.$nextTick(() => {
      this.strainSearch = this.model.strain || "";
      this.debounceStrainSearch(this.strainSearch, this);
    });
    this.loading = false;
  }

  protected updateLoadingWindow(result: Partial<MetrcItem>) {
    EventBus.$emit("mtrcLoadingEvent", {
      show: !!result.errors,
      errorList: result.errors || []
    });
  }

  protected validateModel() {
    if (!this.requiredFields.requires_strain) {
      this.model.strain = null;
    }

    if (!this.requiredFields.requires_unit_thc_content) {
      this.model.unit_thc_content = null;
      this.model.unit_thc_content_unit_of_measure = null;
    }

    if (!this.requiredFields.requires_unit_weight) {
      this.model.unit_weight = null;
      this.model.unit_weight_unit_of_measure = null;
    }

    if (!this.requiredFields.requires_public_ingredients) {
      this.model.ingredients = null;
    }
  }

  protected missingInModel() {
    let result;
    forIn(this.requiredFields, (_, key) => {
      const modelKey = key.replace(/requires_(public_)?/, "");
      if (!findKey(this.model, (v, k) => k === modelKey && v)) {
        result = modelKey;
        return false;
      }
    });
    return result;
  }
}
